.orgSelectWrapper {
  padding: 8px 0;
  min-width: 200px;
}

.orgSelectOptions {
  padding: 4px 0;

  .break {
    height: 1px;
    background-color: var(--light-gray-3);
  }

  .hide {
    display: none;
  }

  input {
    border: none;
    margin: 8px 16px;
  }
  label {
    padding-right: 16px;
  }
}

.orgSelectOption {
  display: flex;
  align-items: center;
}

.orgSelectOption:hover {
  background: var(--light-gray-2);
}

.orgSelectIcon {
  margin: 8px 16px;
  color: var(--green-4);

  visibility: hidden;

  &.isVisible {
    visibility: visible;
  }
}
