.brandSelectWrapper {
  padding: 8px 0;
  min-width: 200px;
}

.brandSelectOptions {
  .break {
    height: 1px;
    background-color: var(--light-gray-3);
  }

  .hide {
    display: none;
  }
  label {
    padding-right: 16px;
  }
}

.brandSelectOption {
  padding: 4px 0;
  display: flex;
  align-items: center;
}

.brandSelectOption:hover {
  background: var(--light-gray-2);
}

.brandSelectIcon {
  margin: 8px 16px;
  color: var(--green-4);

  visibility: hidden;

  &.isVisible {
    visibility: visible;
  }
}
