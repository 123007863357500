@import "../../styles/constants.scss";

.navBarTop {
  background: var(--light-gray-1);
  border-bottom: 1px solid var(--light-gray-5);

  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.navTopAction {
  display: flex;
  align-items: center;

  .kui-button {
    display: flex;
    align-items: center;

    .kui-icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.navLogo {
  width: 170px;
  padding: 16px 0;
}

.navBarBottom {
  display: flex;
  justify-content: space-between;
  padding: 8px 40px;
  max-width: $constrained-width;
  margin: 0 auto;
}

.navButtons {
  display: flex;
}

.navButton {
  font-size: 16px;
  padding: 14px 28px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 2px solid var(--white);
  color: var(--medium-gray-3);
}

.active {
  border-bottom: 2px solid var(--blue-3);
  color: var(--blue-3);
}

.navFilters {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
