@import "./constants.scss";

*, html, body {
  color: var(--dark-gray-5);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
}

.appErrorPage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--light-gray-1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.appErrorModal {
  width: 320px;
  border: 1px solid var(--light-gray-3);
  background-color: var(--white);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-1;
  text-align: center;
}

.appPage {
  padding: 20px 40px 100px 40px;
  max-width: $constrained-width;
  margin: 0 auto;

  h2 {
    font-size: 34px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break {
    margin: 40px 0 46px 0;
    background-color: var(--light-gray-4);
    height: 1px
  }
}

.mr-16 {
  margin-right: 16px;
}
.mr-8 {
  margin-right: 8px;
}

.mt-16 {
  margin-top: 16px;
}
.mt-8 {
  margin-top: 8px;
}

.ml-16 {
  margin-left: 16px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-4 {
  margin-left: 4px;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-8 {
  margin-bottom: 8px;
}


.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.MuiTooltip-tooltip {
  background-color: var(--dark-gray-5) !important;
  .MuiTooltip-arrow {
    color: var(--dark-gray-5);
  }
}