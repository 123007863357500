.unauth__page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, var(--kenect-blue), #123a6d);
  display: flex;
  justify-content: center;
  align-items: center;

  .kui-alert-modal-overlay {
    position: relative;
    background-color: transparent;
  }
}

.modal__icon {
  color: var(---white);
}

#alert-message {
  color: var(--medium-gray-5);
}
