.Loading {
  width: 100%;
  max-width: 54px;
  margin: 0 auto;
  position: relative;
  top: 7vh;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  &__Dot {
    min-width: 15px;
    min-height: 15px;
    background: var(--kenect-blue);
    display: inline-block;
    border-radius: 2px;
    position: relative;
    margin-top: 2px;
    margin-right: 2px;
    &__Active {
      background: pink;
      background: var(--blue-1);
    }
  }
}
