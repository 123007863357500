.iconContainer {
  display: flex;
}

.dropdownCarat {
  transition: all .5s;
}
.rotateCarat {
  transform: rotate(180deg);
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 204px;
  line-height: 1.5;
  border-radius: 8px;
  border: solid 1px var(--medium-gray-3);
  padding: 2px 16px;
  cursor: pointer;

  background-color: white;
  text-align: left;
}

.dropdownTitle {
  font-size: 12px;
  color: var(--medium-gray-4);
}

.dropdownValue {
  font-size: 16px;
}