.navDateRangePicker {
  border-bottom: 1px solid var(--light-gray-2);

  .rdrStaticRanges .rdrStaticRangeSelected .rdrStaticRangeLabel {
    color: var(--white);
  }

  .rdrDateDisplay .rdrDateInput {
    display: flex;
    max-height: 32px;
    justify-content: center;
    align-items: center;
  }
}

.navDateRangePickerActionBar {
  display: flex;
  justify-content: flex-end;
  padding: 18px 16px;
}
